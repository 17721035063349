<template>
  <div>
      <div class="container">
            <b-row class="rowas">
                <div class="col-12 col-sm-12 col-md-12 noPadding plusAncho title">
                    <p><img loading="lazy" alt="lupa" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">Welcome to <span>dashboard</span></p>
                </div>
                <div>
                    <p>What would you like to do today?</p>
                    <!-- <router-link to="/admin/blogs">Blogs & Podcasts</router-link> -->
                    <div class="cardsContainer">
                        <router-link to="/admin/blogs" >
                            <b-card
                                title="Blogs & Podcasts"
                                img-src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/dashboard/blog.jpg"
                                img-alt="Image"
                                img-top
                                tag="article"
                                class="mb-2 card"
                            >
                            </b-card>
                        </router-link>
                    </div>
                </div>
            </b-row>
            <b-row class="clearfix" style="height: 50px;"></b-row>
      </div>
  </div>
</template>

<script>
    export default {
        metaInfo() {
            return {
                title: 'Administration Dashboard'
            }
        }
    }
</script>

<style scoped>
    .container {
        width: 80%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .card {
        margin-right: 5px;
        width: 300px;
    }

    .cardsContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .cardsContainer a {
        color: #232323;
        transition: 0.3s ease;
    }

    .cardsContainer a:hover {
        text-decoration: none;
        opacity: 0.9;
        transition: 0.3s ease;
    }
</style>